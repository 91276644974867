<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const manualTrigger = ref(PushTriggers.MANUAL)

const actions = computed(() => store.getters.sync?.actionQueue || [])
</script>

<template>
  <div>
    <template v-if="actions.length">
      <div class="action-queue">
        <div v-for="(action, idx) in actions" :key="idx" class="action">
          <b>{{ action.id }}</b><br>
          {{ action.timestamp }}<br>
          {{ action.action_type }} {{ action.model }}{{ action.action_route ? ` [${action.action_route}]` : '' }} ({{ action.state }})<br>
          <pre>{{ action.data }}</pre>
        </div>
      </div>
      <div class="buttons">
        <a class="button small" @click="store.dispatch('pushActions', { trigger: manualTrigger, limit: 1 })">
          Push next
        </a>
        <a class="button small" @click="store.dispatch('pushActions', { trigger: manualTrigger })">
          Push all
        </a>
        <span class="spacer" />
        <a class="button small" @click="store.dispatch('clearSyncQueue')">
          Clear queue ({{ store.getters.sync ? store.getters.sync.actionQueue.length : 0 }})
        </a>
      </div>
    </template>
    <p v-else>
      No actions...
    </p>
  </div>
</template>

<style lang="scss" scoped>
.action-queue {
  margin-bottom: 1.5rem;
  .action {
    padding: 1rem;
    font-size: small;
    background-color: hsl(var(--element));
    border-radius: $border-radius;
    &:not(:first-child) {
      margin-top: .75rem;
    }
    pre {
      margin: 0;
    }
  }
}
.buttons {
  display: flex;
  *:not(:first-child) {
    margin-left: 1rem;
  }
}
</style>
